const ArrLocal = [
  {
    text: "ABBOTT",
    value: "ABBOTT"
  },
  {
    text: "ADOLFO GONZALES CHAVEZ",
    value: "ADOLFO-GONZALES-CHAVEZ",
  },
  {
    text: "BAHÍA BLANCA",
    value: "BAHiA-BLANCA"
  },
  {
    text: "BENITO JUAREZ",
    value: "BENITO-JUAREZ",
  },
  {
    text: "BORDENAVE",
    value: "BORDENAVE"
  },
  {
    text: "C.A.B.A.",
    value: "C.A.B.A."
  },
  {
    text: "CABILDO",
    value: "CABILDO"
  },
  {
    text: "CAÑUELAS",
    value: "CAÑUELAS",
  },
  {
    text: "CARMEN DE PATAGONES",
    value: "CARMEN-DE-PATAGONES",
  },
  {
    text: "CARHUÉ",
    value: "CARHUE"
  },
  {
    text: "CLAROMECO",
    value: "CLAROMECO",
  },
  {
    text: "COPETONAS",
    value: "COPETONAS",
  },
  {
    text: "CORONEL DORREGO",
    value: "CORONEL-DORREGO",
  },
  {
    text: "CORONEL PRINGLES",
    value: "CORONEL-PRINGLES"
  },
  {
    text: "CORONEL SUÁREZ",
    value: "CORONEL-SUAREZ"
  },
  {
    text: "DARREGUEIRA",
    value: "DARREGUEIRA"
  },
  {
    text: "DE LA GARMA",
    value: "DE-LA-GARMA",
  },
  {
    text: "G.B.A.",
    value: "G.B.A."
  },
  {
    text: "GENERAL CERRI",
    value: "GENERAL-CERRI"
  },
  {
    text: "GENERAL CONESA",
    value: "GENERAL-CONESA",
  },
  {
    text: "GENERAL LAMADRID",
    value: "GENERAL-LAMADRID",
  },
  {
    text: "HILARIO ASCASUBI",
    value: "HILARIO ASCASUBI",
  },
  {
    text: "INDIO RICO",
    value: "INDIO-RICO",
  },
  {
    text: "INGENIERO WHITE",
    value: "INGENIERO-WHITE",
  },
  {
    text: "JUAN A. PRADERE",
    value: "JUAN-A.-PRADERE",
  },
  {
      text: "LAPRIDA",
    value: "LAPRIDA",
  },
  {
    text: "LAS GRUTAS",
    value: "LAS-GRUTAS",
  },
  {
    text: "MAYOR BURATOVICH",
    value: "MAYOR-BURATOVICH",
  },
  {
    text: "MÉDANOS",
    value: "MEDANOS",
  },
  {
    text: "MONTE HERMOSO",
    value: "MONTE-HERMOSO",
  },
  {
    text: "ORENSE",
    value: "ORENSE",
  },
  {
    text: "ORIENTE",
    value: "ORIENTE",
  },
  {
    text: "PEDRO LURO",
    value: "PEDRO-LURO",
  },
  {
    text: "PIGUE",
    value: "PIGUE",
  },
  {
    text: "PUAN",
    value: "PUAN",
  },
  {
    text: "PUERTO BELGRANO",
    value: "PUERTO-BELGRANO",
  },
  {
    text: "PUNTA ALTA",
    value: "PUNTA-ALTA",
  },
  {
    text: "RETA",
    value: "RETA",
  },
  {
    text: "RIO COLORADO",
    value: "RIO-COLORADO",
  },
  {
    text: "ROQUE PEREZ",
    value: "ROQUE-PEREZ",
  },
  {
    text: "SAAVEDRA",
    value: "SAAVEDRA",
  },
  {
    text: "SAN ANTONIO OESTE",
    value: "SAN-ANTONIO-OESTE",
  },
  {
    text: "SAN CAYETANO",
    value: "SAN-CAYETANO",
  },
  {
    text: "SAN MIGUEL DEL MONTE",
    value: "SAN-MIGUEL-DEL-MONTE",
  },
  {
    text: "SALDUNGARAY",
    value: "SALDUNGARAY",
  },
  {
    text: "SIERRA DE LA VENTANA",
    value: "SIERRA DE LA VENTANA",
  },
  {
    text: "STROEDER",
    value: "STROEDER",
  },
  {
    text: "TORNQUIST",
    value: "TORNQUIST",
  },
  {
    text: "TRES ARROYOS",
    value: "TRES-ARROYOS",
  },
  {
    text: "VILLALONGA",
    value: "VILLALONGA",
  },
  {
    text: "VIEDMA",
    value: "VIEDMA",
  },
  {
    text: "LOBOS",
    value: "LOBOS",
  },
  {
    text: "NAVARRO",
    value: "NAVARRO",
  },
  {
    text: "LAS HERAS",
    value: "LAS HERAS",
  },
  {
    text: "URIBELARREA",
    value: "URIBELARREA",
  }
];

export default ArrLocal;
