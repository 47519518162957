import React from "react";
import {
	useTheme,
	useMediaQuery,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DrawerComponent from "../nav-bar/drawer";
import { HashLink } from 'react-router-hash-link';

const namespace = 'expreso-el-vasquito';

function NavBar() {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const arrMenu = [
		{
			link: 'logistica-360',
			text: '360°',
		},
		{
			link: 'sucursales',
			text: 'Sucursales',
		},
		{
			link: 'recursos-humanos',
			text: 'rrhh',
		}
	];

	 const menuChildren = arrMenu.map(
		e =>
			<Link
				key={`/${e.link}`}
				to={`/${e.link}`}
				className="link-menu"
			>
        {e.text}
			</Link>
	);
	
  return (
		<header className="content-app">
			<div className={`content-app ${namespace}__content`}>
        <Link
          to={`/`}
        >
          <img className="img-logo" src='logo.png' alt='Logo' />
        </Link>
				{isMobile ? (<DrawerComponent />) : (
					<div className="navbar-content">
						<HashLink className="link-menu" to={`/QUOTER#expreso-el-vasquito`}>Cotizador</HashLink>
						<HashLink className="link-menu" to={`/RETIREMENT#expreso-el-vasquito`}>Retiro</HashLink>
						{menuChildren}
						<HashLink className="link-menu" to={`/#contacto`}>Contacto</HashLink>
						<a target="_blank" href={`https://seguimiento.expresoelvasquito.com.ar`} className="link-menu link-menu-seguimiento">Portal de seguimiento</a>
					</div>
				)}
			</div>
		</header>
	);

}
export default NavBar;
