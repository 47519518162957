import React from 'react'
import { Link } from "react-router-dom";

const namespace = 'expreso-el-vasquito';

export default function CardSucursales({ id, index, name, adress, citi, tel, mail, hour, button }) {
  return (
    <div className={`card_sucursales`}>
      <img className={`${namespace}__card-img`} src={`assets/jpg/${index+1}.jpg`} alt="img"/>
      <span className={`name`}>{name}</span>
      <span className={'descripcion'}>{adress}</span>
      <span className={'descripcion'}>{citi}</span>
      <span className={'descripcion'}>{tel}</span>
      <span className={`mail`}>{mail}</span>
      <span className={'descripcion'}>{hour}</span>
      {
        button ?
        <Link className={`button_sucursales`} to={`/sucursales?location=${id}`}>Ver</Link>
        : ''
      }
    </div>
  )
}
