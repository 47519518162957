import React from "react";

export default function Logistica() {
  const namespace = 'expreso-el-vasquito';
	
  return(
    <section className={`content-app  ${namespace}_logistica`}>
    <img className={`${namespace}_imgtitle`} src={"/assets/360/360-logo.png"}/>
    <div className={`${namespace}_content-text-iframe`}>
      <div className={`${namespace}_content-text`}>
        <p className={`${namespace}_text`}>Logística360 se gestó en el año 2019 con el fin de formar una Red logística para poder llegar a todas las localidades de nuestro país, con el señor Maximo Etcheverry como consultor y coordinador.
    El objetivo fundamental de Logistica360 es unir a distintos transportes y así poder prestar servicio a nivel nacional, manteniendo los estándares de calidad de servicio y ofrecer al cliente llegar a una mayor cantidad de localidades desde cualquier punto del país.
    Cada una de las empresas que componen la Red, cuentan con muchos años de experiencia en el rubro, pudiendo brindar un servicio de excelencia en todo el país basado principalmente en cargas generales, servicio expreso, cargas consolidadas y el e-commerce.
    Expreso El Vasquito está en la búsqueda constante de la satisfacción del cliente, y ofrecemos desde nuestro lugar todas las herramientas para que puedan crecer, por eso ahora desde su casa o su comercio, vamos a llegar juntos a todo el país.</p>
        <br />
        <p className={`${namespace}_text`}>Transportes que conforman la red:</p>
        <div className={'expreso-el-vasquito_alianza_container'}>
          <div className={'expreso-el-vasquito_alianza_container_img'}>
            <a target="_blank" href="https://www.expresoelvasquito.com.ar/" alt="expresso el vasquito"><img className={`${namespace}_imgalianza`} src="/assets/360/expresoelvasquito.png" /></a>
          </div>
          <div className={'expreso-el-vasquito_alianza_container_img'}>
            <a target="_blank" href="https://sinerlogic.com/" alt="sinerlogic"><img className={`${namespace}_imgalianza`} src="/assets/360/sinerlogic.png" /></a>
          </div>
          <div className={'expreso-el-vasquito_alianza_container_img'}>
            <a target="_blank" href="https://www.transportegonzalez.com/" alt="transportegonzalez"><img className={`${namespace}_imgalianza`} src="/assets/360/transportegonzalez.png" /></a>
          </div>
          <img className={`${namespace}_imgalianza`} src="/assets/360/expresotaragui.png" />
          <div className={'expreso-el-vasquito_alianza_container_img'}>
            <a target="_blank" href="https://salma.com.ar/" alt="salma"><img className={`${namespace}_imgalianza`} src="/assets/360/salmatransporte.png" /></a>
          </div>
          <div className={'expreso-el-vasquito_alianza_container_img'}>
            <a target="_blank" href="https://www.raosa.com.ar/" alt="raosa"><img className={`${namespace}_imgalianza`} src="/assets/360/raosa.png" /></a>
          </div>
          <div className={'expreso-el-vasquito_alianza_container_img'}>
            <a target="_blank" href="https://www.expresonuevovalle.com.ar/" alt="expresonuevovalle"><img className={`${namespace}_imgalianza`} src="/assets/360/nuevovalle.png" /></a>
          </div>
          <div className={'expreso-el-vasquito_alianza_container_img'}>
            <a target="_blank" href="https://ascencio.com.ar/" alt="ascencio"><img className={`${namespace}_imgalianza`} src="/assets/360/transportesascencio.png" /></a>
          </div>
          <div className={'expreso-el-vasquito_alianza_container_img'}>
            <a target="_blank" href="https://www.transalmafuerte.com/" alt="transalmafuerte"><img className={`${namespace}_imgalianza`} src="/assets/360/transportesalmafuerte.png" /></a>
          </div>
          <div className={'expreso-el-vasquito_alianza_container_img'}>
            <a target="_blank" href="https://logisticapampeana.com/" alt="logisticapampeana"><img className={`${namespace}_imgalianza`} src="/assets/360/logisticapampeana.png" /></a>
          </div>
          <div className={'expreso-el-vasquito_alianza_container_img'}>
            <a target="_blank" href="https://transportepetrel.com/" alt="transportepetrel"><img className={`${namespace}_imgalianza`} src="/assets/360/transportepetrel.png" /></a>
          </div>
        </div>
      </div>
    </div>
</section>)
}