import Axios from '../axios';

export class RetirementServices {
  static async postRetirement(body) {
    const obj = {
      solicitante_name: body.solicitante_name,
      solicitante_telefono: body.solicitante_telefono,
      solicitante_email: body.solicitante_email,

      destinatario_name: body.destinatario_name,
      destinatario_direccion: body.destinatario_direccion,
      destinatario_location: body.destinatario_location,
      destinatario_location_localidad: (body.destinatario_location_localidad) ? body.destinatario_location_localidad : '',
      destinatario_telefono: body.destinatario_telefono,

      remitente_name: body.remitente_name,
      remitente_direccion: body.remitente_direccion,
      remitente_location: body.remitente_location,
      remitente_location_localidad: (body.remitente_location_localidad) ? body.remitente_location_localidad : '',
      remitente_telefono: body.remitente_telefono,

      descripcion: body.descripcion,
      peso: body.peso,
      volumen: body.volumen,
      valor_declarado: body.valor_declarado,
    }
    try {
      await Axios.post('https://www.expresoelvasquito.com.ar/utils/retiro.php', obj);
    } catch (error) {
      console.log(error);
      return error;
    }
  }
};
