import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const namespace = 'expreso-el-vasquito';

export default function Footer() {
	return (
		<footer className="content-app">
			<div className={`content-app ${namespace}__footer`}>
        <Link
          to={`/`}
        >
          <img className="logo-footer" src='logo.png' alt='Logo' />
        </Link>
					<div className="navbar-content">
            <HashLink className="link-menu" to={`/QUOTER#expreso-el-vasquito`}>Cotizador</HashLink>
            <HashLink className="link-menu" to={`/RETIREMENT#expreso-el-vasquito`}>Retiro</HashLink>
            <Link
              key={`/recursos-humanos`}
              to={`/recursos-humanos`}
              className="link-menu"
            >rrhh</Link>
					</div>
			</div>
		</footer>
	);

}

