import React, { useState, useEffect } from "react";
import CardSucursales from '../../component/utils/card-sucursales';
import arrInfo from '../../component/sucursales/data';
import { IframeDefault,
  BahiaBlanca,
  PedroLuro,
  TresArroyos,
  Viedma,
  Lobos,
} from './data-iframe';
import {useSearchParams} from 'react-router-dom';

export default function Sucursales() {
  const [value, setValue] = useState('buenos-aires');
  const [locationMap, setLocationMap] = useState(arrInfo[0]);
  const [searchParams, setSearchParams] = useSearchParams();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const page_type = urlParams.get('location')

  useEffect(() => {
    if (page_type) {
      setValue(page_type)
    }
    let result = arrInfo.find(el => el.id === value);
    setLocationMap(result);
  }, [value]); 


  const removeQueryParams = () => {
    const param = searchParams.get('location');
    if (param) {
      searchParams.delete('location');
      setSearchParams(searchParams);
    }
  };

  const namespace = 'expreso-el-vasquito';
  const handleChange = (event) => {
    removeQueryParams();
    setValue(event.target.value);
  }

  const identifyCity = () => {
    switch (value) {
      case 'buenos-aires':
      return (
        <IframeDefault/>
      )
      case 'bahia-blanca':
      return (
        <BahiaBlanca/>
      )
      case 'pedro-luro':
      return (
        <PedroLuro/>
      )
      case 'viedma':
      return (
        <Viedma/>
      )
      case 'tres-arroyos':
      return (
        <TresArroyos/>
      )
      case 'lobos':
      return (
        <Lobos/>
      )
      default:
      return (
        <IframeDefault/>
      )
    }
  }

	return(
    <div className={`content-app ${namespace}--sucursales`}>
      <div className={`content-app ${namespace}--img-select`}>
       <h2 className={`${namespace}_title`}>Sucursales</h2>
       <select value={value} onChange={handleChange}>
          <option value="default" disabled>Elegí tu ubicación</option>
          <option value="buenos-aires">Buenos Aires (Casa Central)</option>
          <option value="bahia-blanca">Bahía Blanca (Buenos Aires)</option>
          <option value="pedro-luro">Pedro Luro (Buenos Aires)</option>
          <option value="viedma">Viedma (Río Negro)</option>
          <option value="tres-arroyos">Tres Arroyos (Buenos Aires)</option>
          <option value="lobos">Lobos (Buenos Aires)</option>
        </select>
        <CardSucursales index={0} {...locationMap} button={false} />
      </div>
      <div className={`content-app ${namespace}--iframe`}>
        {identifyCity()}
      </div>
    </div>
	)
}