import React from "react";
import arrInfo from './data';
import CardSucursales from '../utils/card-sucursales';

const namespace = 'expreso-el-vasquito';

function Sucursales() {
	return(
		<div className={`content-app sucursales`}>
      <h2 className={`${namespace}_title`}>Sucursales</h2>
      <div className={`content-app ${namespace}__sucursales`}>
        {arrInfo.map((e, index) =>
          <CardSucursales index={index} {...e} button="sucursales"/>
        )}
      </div>
		</div>
	);
}

export default Sucursales;