import React, { useState, useEffect } from 'react';
import Retirement from '../retirement';
import Quoter from '../quoter';

const namespace = 'expreso-el-vasquito';

function MainComponent(props) {
	const QUOTER = 'QUOTER';
	const RETIREMENT = 'RETIREMENT';
	const [viewForm, setViewForm] = useState(QUOTER);

  useEffect(() => {
    if (props && props.page) {
      setViewForm(props.page);
    }
  }, [props.page]); 

  const cotizadorClassName = viewForm === QUOTER ? `${namespace}__button cotizador active` : `${namespace}__button cotizador`;
  const retiroClassName = viewForm === RETIREMENT ? `${namespace}__button retiro active` : `${namespace}__button retiro`;
  
  return (
		<div id="expreso-el-vasquito" className="content-main-banner">
			<div className={`content-app ${namespace}__banner`}>
				<div className={`${namespace}__content-title`}>
					<p className={`${namespace}__title`}>Seguridad y rapidez,</p>
					<p className={`${namespace}__title`}>sinónimo de economía</p>
					<span className={`${namespace}__subtitle first-child`}>Con nuestro cotizador online podés pedir</span>
					<span className={`${namespace}__subtitle`}>presupuesto, y gestionar retiros</span>
				</div>
				<div className={`${namespace}__form`}>
					<button className={cotizadorClassName} onClick={() => setViewForm(QUOTER)}>Cotizador</button>
					<button className={retiroClassName} onClick={() => setViewForm(RETIREMENT)}>Retiro</button>
					{viewForm === QUOTER ? <Quoter/> : <Retirement/>}
				</div>
			</div>
		</div>
	);
}

export default MainComponent;
