import React, { useState } from "react";
import {
	Divider,
	Drawer,
	IconButton,
	makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { HashLink } from 'react-router-hash-link';
import './index.scss';

const useStyles = makeStyles(()=>({
	icon:{
		color: "#505050"
	},
	drawer: {
		width: "500px"
	}
}));

function DrawerComponent() {
	const classes = useStyles();
	const [openDrawer, setOpenDrawer] = useState(false);

	const arrMenu = [
		{
			link: 'sucursales',
			text: 'Sucursales',
		},
		{
			link: 'logistica-360',
			text: '360°',
		},
		{
			link: 'recursos-humanos',
			text: 'rrhh',
		},
	];

  return (
		<div>
			<Drawer
				classes={{paper: "mobile-menu-content"}}
				open={openDrawer}
				onClose={() => setOpenDrawer(false)}
			>
        <div className="content-link-menu">
          <img className="mobile-img-logo" src='logo.png' width={'200px'} alt='Logo' />
          <span className="link-menu" onClick={() => setOpenDrawer(false)}>X</span>
        </div>
        <hr class="MuiDivider-root link-menu-divider" />
        <div className="link-menu" onClick={() => setOpenDrawer(false)}>
          <HashLink className="link-menu-child" key={`menu-cotizador`} to={`/QUOTER#expreso-el-vasquito`}>Cotizador</HashLink>
          <hr class="MuiDivider-root link-menu-divider" />
        </div>
        <div className="link-menu" onClick={() => setOpenDrawer(false)}>
          <HashLink className="link-menu-child" key={`menu-retiro`} to={`/RETIREMENT#expreso-el-vasquito`}>Retiro</HashLink>
          <hr class="MuiDivider-root link-menu-divider" />
        </div>
				{arrMenu.map(
					e => <div className="link-menu" onClick={() => setOpenDrawer(false)}>
						<Link className="link-menu-child" key={`menu-${e.link}`} to={`/${e.link}`}>
						  {e.text}
						</Link>
				<Divider className="link-menu-divider" />
					</div>
				)}
        		<HashLink className="link-menu" key={`menu-contacto`} to={`/#contacto`} onClick={() => setOpenDrawer(false)}>
					Contacto
					<Divider className="link-menu-divider" />
				</HashLink>

				<a onClick={() => setOpenDrawer(false)} target="_blank" href={`https://seguimiento.expresoelvasquito.com.ar`} className="link-menu link-menu-seguimiento">
					Portal de seguimiento
					<Divider className="link-menu-divider" />
				</a>
			</Drawer>
			<IconButton onClick={() => setOpenDrawer(!openDrawer)} className={classes.icon}>
				<MenuIcon />
			</IconButton>
		</div>
  );
}
export default DrawerComponent;
