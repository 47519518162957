const arrInfo = [
  {
    id: 'buenos-aires',
    name: 'Buenos Aires (Casa Central)',
    adress: 'Pergamino 3751 – Módulos 38 y 39',
    citi: 'CABA',
    tel: '2206-2600',
    mail: 'info@expresoelvasquito.com.ar',
    hour: '8 a 17 hs',
  },
  {
    id: 'bahia-blanca',
    name: 'Bahía Blanca (Buenos Aires)',
    adress: 'Charlone 2449',
    citi: '', 
    tel: '(0291) 154422000 / 154424000',
    mail: 'bahiablanca@expresoelvasquito.com.ar',
    hour: '8 a 17 hs',
  },
  {
    id: 'pedro-luro',
    name: 'Pedro Luro (Buenos Aires)',
    adress: 'Ruta 3 km 807',
    citi: '', 
    tel: '(02928) 42-0160',
    mail: 'pedroluro@expresoelvasquito.com.ar',
    hour: '8 a 17 hs',
  },
  {
    id: 'viedma',
    name: 'Viedma (Río Negro)',
    adress: 'Calle 207 Nº1675, Parque Industrial',
    citi: '', 
    tel: '(02920) 433032 / 433034',
    mail: 'viedma2@expresoelvasquito.com.ar',
    hour: '8 a 12 – 15:30 a 19 hs',
  },
  {
    id: 'tres-arroyos',
    name: 'Tres Arroyos (Buenos Aires)',
    adress: 'Benito Machado 930',
    citi: '', 
    tel: '(02983) 43-0200',
    mail: 'tresarroyos@expresoelvasquito.com.ar',
    hour: '8 a 17 hs',
  },
  {
    id: 'lobos',
    name: 'Lobos (Buenos Aires)',
    adress: 'Tagliero 1176',
    citi: '', 
    tel: '(02227) 1547-2009',
    mail: 'lobos@expresoelvasquito.com.ar',
    hour: '8 a 17 hs',
  },
];

export default arrInfo;
