import React, {useState} from 'react';
import { useForm } from "react-hook-form";
import ArrLocal from '../utils/localidades';
import { RetirementServices } from '../../services/retirement';
import Modal from '../modal';

const namespace = 'expreso-el-vasquito';

const Select = React.forwardRef(({ onChange, name, label }, ref) => (
  <>
    <label>{label}</label>
    <select name={name} ref={ref} onChange={onChange}>
      {
        ArrLocal.map(e => (
          <option name={e.value} value={e.value}>{e.text}</option>
        ))
      }
    </select>
  </>
));

function Retirement() {
  const [open, setOpen] = React.useState(false);
  const [addlocalidadrem, setAddlocalidadrem] = React.useState(false);
  const [addlocalidaddes, setAddlocalidaddes] = React.useState(false);
  const handleClose = () => setOpen(false);

  const handleChangeRemitenteLocation = (e) => (e.target.value === "G.B.A.") ?  setAddlocalidadrem(true) : setAddlocalidadrem(false);
  const handleChangeDestinatarioLocation = (e) => (e.target.value === "G.B.A.") ?  setAddlocalidaddes(true) : setAddlocalidaddes(false);

  const { register, handleSubmit, getValues, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    RetirementServices.postRetirement(data);
    setOpen(true);
  };

  const req = { required: true };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
      />
      <form onSubmit={handleSubmit(onSubmit)} className={`${namespace}--form-retirement`}>
        <p className="form-description-retiros">Los retiros a domicilio deben ser solicitados como mínimo un día hábil antes de la fecha deseada, hasta las 16hs.Dentro de CABA se gestionarán para el día siguiente, por otras localidades consultar.</p>
        <h5 className={`${namespace}--form-title`}>Datos del solicitante</h5>
        <div className="content-form">
          <input className={`${namespace}--input`} placeholder="Nombre / Razón social" {...register("solicitante_name", req)} />
          <input className={`${namespace}--input right`} placeholder="email" {...register("solicitante_email", req)} type="email"  />
          <input className={`${namespace}--input`} placeholder="Teléfono" {...register("solicitante_telefono", req)} type="number" />
        </div>

        <h5 className={`${namespace}--form-title`}>Datos del Remitente:</h5>
        <div className="content-form remitente">
          <input placeholder="Nombre / Razón social" {...register("remitente_name", req)} />
          <input placeholder="Dirección" {...register("remitente_direccion", req)} />
          <Select {...register("remitente_location", {...req, onChange: handleChangeRemitenteLocation})} />
          <input placeholder="Teléfono" {...register("remitente_telefono", req)} type="number" />
          {addlocalidadrem && <input placeholder="Localidad del remitente" {...register("remitente_location_localidad", req)} />}
        </div>

        <h5 className={`${namespace}--form-title`}>Datos del Destinatario:</h5>
        <div className="content-form destinatario">
          <input placeholder="Nombre / Razón social" {...register("destinatario_name", req)} />
          <input placeholder="Dirección" {...register("destinatario_direccion", req)} />
          <Select {...register("destinatario_location", {
            ...req,
            onChange: handleChangeDestinatarioLocation,
            validate: v =>  v !== getValues("remitente_location")})} />
          <input placeholder="Teléfono" {...register("destinatario_telefono", req)} type="number" />
          {addlocalidaddes && <input placeholder="Localidad del destinatario" {...register("destinatario_location_localidad", req)} />}
          {(errors && errors.destinatario_location) ? <span role="alert" className={"form-error-same-locations"}>
            La localidad del destinatario debe ser diferente a la localidad del remitente
          </span> : ''}
        </div>

        <h5 className={`${namespace}--form-title`}>Detalle de la mercadería</h5>
        <div className="content-form destinatario">
          <input placeholder="Descripción" {...register("descripcion", req)} />
          <input placeholder="Peso (Kg)" type="number" {...register("peso", req)} />
          <input placeholder="Volumen (m3)" type="number" {...register("volumen", req)} />
          <input placeholder="Valor declarado ($ARS)" type="number" {...register("valor_declarado", req)} />
        </div>
        <p className="form-description-valores">*Toda la mercadería debe estar embalada, caso contrario no se efectuará el retiro. Las motos solo se retiran en cajón. Los vidrios solo se retiran en cajón.El transporte no se responsabiliza por roturas en caso de mal embalaje o embalaje deficiente.</p>

        <input className={`${namespace}--input`} type="submit" value="Enviar" />
      </form>
    </>
  );
}

export default Retirement;