export const IframeDefault = () => {
  return (
    <iframe src="https://www.google.com/maps/d/u/1/embed?mid=1v4YWGxAn4LYLquAsLT-mugiFQj6ADLU&ehbc=2E312F" width="640" height="480"></iframe>
  )
};

export const BahiaBlanca = () => {
  return (
    <iframe src="https://www.google.com/maps/d/u/2/embed?mid=1NnSqQ1mWy3xSw3cSEAquCdw2FB_pBsQ&ehbc=2E312F" width="640" height="480"></iframe>
  )
}

export const PedroLuro = () => {
  return (
    <iframe src="https://www.google.com/maps/d/u/2/embed?mid=1FrqRZLXusEJx464OSOLn6i-209bnTXA&ehbc=2E312F" width="640" height="480"></iframe>
  )
}

export const TresArroyos = () => {
  return (
    <iframe src="https://www.google.com/maps/d/u/2/embed?mid=1Zdp8rb6L6rqcZWVAYzcZ7Wvpd0cUQ2A&ehbc=2E312F" width="640" height="480"></iframe>
  )
}

export const Viedma = () => {
  return (
    <iframe src="https://www.google.com/maps/d/u/2/embed?mid=1xZl0JioAxb6F8ZuKl6cKF31IUWD9WPs&ehbc=2E312F" width="640" height="480"></iframe>
  )
}

export const Lobos = () => {
  return (
    <iframe src="https://www.google.com/maps/d/u/2/embed?mid=15rGLHBUIbSpL-G4Guh7xxzjGsXBkQNM&ehbc=2E312F" width="640" height="480"></iframe>
  )
}