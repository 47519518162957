import Axios from '../axios';

export class ContactServices {
  static async postContact(body) {
    const obj = {
      subject: "Contacto desde ExpresoVasquito.com.ar",
      name: body.name,
      email: body.email,
      sucursalMail: body.sucursalMail,
      message: body.message,
      tell: body.tell,
    }
    try {
      await Axios.post('https://www.expresoelvasquito.com.ar/utils/mailnew.php', obj);
    } catch (error) {
      console.log(error);
      return error;
    }
  }
};
