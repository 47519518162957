import Axios from '../axios';

export class QuoterServices {
  static async postQuoter(body) {
    const obj = {
      solicitante_name: body.data.solicitante_name,
      solicitante_email: body.data.solicitante_email,
      solicitante_telefono: body.data.solicitante_telefono,
      remitente_name: body.data.remitente_name,
      remitente_direccion: body.data.remitente_direccion,
      remitente_location: body.data.remitente_location,
      remitente_location_localidad: (body.data.remitente_location_localidad) ? body.data.remitente_location_localidad : '',
      remitente_telefono: body.data.remitente_telefono,
      destinatario_name: body.data.destinatario_name,
      destinatario_direccion: body.data.destinatario_direccion,
      destinatario_location: body.data.destinatario_location,
      destinatario_location_localidad: (body.data.destinatario_location_localidad) ? body.data.destinatario_location_localidad : '',
      destinatario_telefono: body.data.destinatario_telefono,
      retiro_a_domicilio: body.data.retiro_a_domicilio,
      seguro_propio: body.data.seguro_propio,
      items: body.items
    }
    try {
      await Axios.post('https://www.expresoelvasquito.com.ar/utils/cotizacion.php', obj);
    } catch (error) {
      console.log(error);
      return error;
    }
  }
};
