import React from "react";
import Data from './data';
import { HashLink } from 'react-router-hash-link';

const namespace = 'expreso-el-vasquito';

function Informacion() {
  return(
    <div className={`${namespace}__informacion`}>
      <div className="content-app">
        <h2 className={`${namespace}_title`}>Información</h2>
        <div className={`content-app ${namespace}__nosotros-left`}>
          {Data.map((e, index) =>
            <div className={`card-informacion`}>
              <img className={`${namespace}__image-svg`} src={`assets/svg/${index+1}.svg`} alt="img"/>
              <p className={`card-informacion-text`}>{e.text}</p>
            </div>
          )}
        </div>
        <div className={`${namespace}-content-buttons-sucursales`}>
          <HashLink className={`button_informacion`} to={`/QUOTER#expreso-el-vasquito`}>Cotizador</HashLink>
          <HashLink className={`button_informacion`} to={`/RETIREMENT#expreso-el-vasquito`}>Retiro</HashLink>
        </div>
      </div>
    </div>
  )
}

export default Informacion;