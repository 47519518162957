import React from "react";

const namespace = 'expreso-el-vasquito';

function Nosotros() {
  return(
    <div className={`content-app ${namespace}__nosotros`}>
      <div className={`${namespace}__nosotros-left first-img`}>
        <img className={`${namespace}_`} src={'nosotros1.png'} alt="img"/>
      </div>
      <div className={`${namespace}__nosotros-left nosotros`}>
        <h2 className={`${namespace}_title`}>Nosotros</h2>
        <p className={`${namespace}_`}>Expreso El Vasquito S.A. es una empresa de servicio de transporte de cargas generales con más de 60 años de experiencia y constante aporte en el desarrollo de la provincia de Buenos Aires y la Patagonia.</p>
        <p className={`${namespace}_`}>Un 24 de Noviembre de 1958, a bordo de un camión Ford 900, comenzaba un emprendimiento en un pequeño pueblo llamado Pedro Luro, al sur de la provincia de Buenos Aires, que hoy se convertiría en una Organización con más de 80 empleados, con casa central en el Centro de Transferencia de Cargas, ubicado en Villa Soldati, 5 sucursales propias y más de 40 localidades atendidas.</p>
        <p className={`${namespace}_ ${namespace}_subtitle-nosotros`}>Misión</p>
        <p className={`${namespace}_`}>Brindar un servicio de transporte de cargas en los diversos sectores de la economía nacional, para satisfacer las necesidades y expectativas de las partes interesadas, con el compromiso de nuestro recurso humano calificado, apoyado con la regulación y control de las operaciones dentro del marco legal de la República Argentina.</p>
      </div>
      <div className={`${namespace}__nosotros-left three`}>				
        <p className={`${namespace}_ ${namespace}_subtitle-nosotros`}>Visión</p>
        <p className={`${namespace}_`}>Ser una empresa de transporte de carga que ofrezca un servicio de óptima calidad, garantizando eficiencia, eficacia, seguridad y rentabilidad en los procesos.</p>
        <p className={`${namespace}_ ${namespace}_subtitle-nosotros`}>Valores</p>
        <p className={`${namespace}_`}>
          <em>Puntualidad:</em> Garantizar a nuestro clientes el mejor recorrido y llegada a su destino de nuestra flota, con la carga en perfectas condiciones, minimizando contratiempos y manteniendo la calidad de nuestro servicio de acuerdo a sus necesidades.
        </p>
        <p className={`${namespace}_`}>
          <em>Seguridad:</em> Contamos con personal capacitado a bordo de las unidades y rastreo satelital de nuestra flota.
        </p>
        <p className={`${namespace}_`}>
          <em>Confianza:</em> Basamos nuestro servicio en la mayor fidelidad y lealtad a nuestros clientes, cumpliendo sus requerimientos.
        </p>
        <p className={`${namespace}_`}>
          <em>Competitividad:</em> Ofrecemos servicios de calidad, con eficiencia, eficacia y a precios competitivos.
        </p>
        <p className={`${namespace}_`}>
          <em>Honestidad y respeto:</em> Tratamos a nuestros clientes con total franqueza y asumiendo nuestras responsabilidades.
        </p>
      </div>
      <div className={`${namespace}__nosotros-left ${namespace}_img-rigth`}>
        <img className={`${namespace}_`} src={'nosotros2.png'} alt="img"/>
      </div>
    </div>
  )
}

export default Nosotros;