import React from 'react';
import { useForm } from "react-hook-form";
import Data from "../sucursales/data";
import { ContactServices } from '../../services/contact';
import Modal from '../modal';

const namespace = 'expreso-el-vasquito';

function Contacto() {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    ContactServices.postContact(data);
    setOpen(true);
	};

	return(
    <>
      <Modal
        open={open}
        onClose={handleClose}
      />
      <div className="content_contacto">
        <div className={`${namespace}__contacto-card`}>
          <h2 id="contacto" >Dejanos tu consulta</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
          <div className="content-form destinatario">
            <input className={`${namespace}--input`} {...register("name")} placeholder="Nombre" />
            <input className={`${namespace}--input left`} {...register("email")} type="email" placeholder="email" required/>
            <input className={`${namespace}--input`} {...register("tell")} placeholder="Teléfono" type="number" />
            <select {...register("sucursalMail")}>
              {Data.map(e => <option value={e.mail}>{e.name}</option>)}
            </select>
          </div>
            <textarea {...register("message")} placeholder="Hola! Dejanos tu mensaje..." />
            <input className={`${namespace}--input`} type="submit" />
          </form>
        </div>
      </div>
    </>
	);
}

export default Contacto;