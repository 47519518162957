import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import ReactTooltip from 'react-tooltip';
import ArrLocal from '../utils/localidades';
import { QuoterServices } from '../../services/quoter';
import Modal from '../modal';

const namespace = 'expreso-el-vasquito';

const Select = React.forwardRef(({ onChange, name, label }, ref) => (
  <>
    <label>{label}</label>
    <select name={name} ref={ref} onChange={onChange}>
      {
        ArrLocal.map(e => (
          <option value={e.value}>{e.text}</option>
        ))
      }
    </select>
  </>
));

function Quoter() {
  const { register, handleSubmit, getValues, formState: { errors } } = useForm();
  const [item, setItem] = useState({});
  const [arrayItems, setArrayItems] = useState([]);
  const [nombre, setNombre] = useState('');
  const [cantidad, setCantidad] = useState('');
  const [largo, setLargo] = useState('');
  const [ancho, setAncho] = useState('');
  const [profundidad, setProfundidad] = useState('');
  const [kilos, setKilos] = useState('');
  const [valor, setValor] = useState('');
  const [valueKey, setValueKey] = useState();
  const [disableErrorMessage, setDisableErrorMessage] = useState(true);
  const [showItems, setShowItems] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [productoVacio, setProductoVacio] = React.useState(false);
  const [addlocalidadrem, setAddlocalidadrem] = React.useState(false);
  const [addlocalidaddes, setAddlocalidaddes] = React.useState(false);
  const handleClose = () => setOpen(false);

  const handleChangeRemitenteLocation = (e) => (e.target.value === "G.B.A.") ?  setAddlocalidadrem(true) : setAddlocalidadrem(false);
  const handleChangeDestinatarioLocation = (e) => (e.target.value === "G.B.A.") ?  setAddlocalidaddes(true) : setAddlocalidaddes(false);

  useEffect(() => {
    arrayItems[valueKey] = item;
    setNombre('');
    setCantidad('');
    setLargo('');
    setAncho('');
    setProfundidad('');
    setKilos('');
    setValor('');
    setProductoVacio(false);
  }, [item]); 

  const onSubmit = (data) => {
    if (handleSubmitItem(undefined)){
      let newData = {};
      newData.data = data;
      newData.items = arrayItems;
      QuoterServices.postQuoter(newData);
      setOpen(true);
    } else {
      setProductoVacio(true);
    }
  };
	
  const handleSubmitItem = (event) => {
    if (event !== undefined) {
      event.preventDefault();
    }
    if (nombre && cantidad && largo && ancho && profundidad && kilos && valor) {
      setValueKey(arrayItems.length);
      let newItem = {
        nombre: nombre,
        cantidad: cantidad,
        largo: largo,
        ancho: ancho,
        profundidad: profundidad,
        kilos: kilos,
        valor: valor,
        id: valueKey || 0,
      }
      setDisableErrorMessage(true);
      setItem(newItem);
      setShowItems(true);
      return true;
    } else {
      if (nombre || cantidad || largo || ancho || profundidad || kilos || valor) {
        setDisableErrorMessage(false);
        return false;
      } else {
        if (event === undefined && arrayItems.length > 0) {
          return true;
        } else {
          setDisableErrorMessage(false);
          return false
        }
      }
    }
  };

  const handleDeleteItem = (event, nombre) => {
    event.preventDefault();
    const newArray = arrayItems.filter(arr => arr.nombre !== nombre)
    setArrayItems(newArray);
  }
	const req =  { required: true };

	return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <h5 className={`${namespace}--form-title`}>Datos del solicitante</h5>
        <div className="content-form">
          <input className={`${namespace}--input`} placeholder="Nombre / Razón social" {...register("solicitante_name", req)} />
          <input className={`${namespace}--input right`} placeholder="email" {...register("solicitante_email", req)} type="email" />
          <input className={`${namespace}--input`} placeholder="Teléfono" {...register("solicitante_telefono", req)} type="number" />
        </div>

        <h5 className={`${namespace}--form-title`}>Datos del Remitente</h5>
        <div className="content-form remitente">
          <input className={`${namespace}--input`} placeholder="Nombre / Razón social" {...register("remitente_name", req)} />
          <input className={`${namespace}--input`} placeholder="Direccion" {...register("remitente_direccion", req)} />
          <Select {...register("remitente_location", {...req, onChange: handleChangeRemitenteLocation})} />
          <input className={`${namespace}--input`} placeholder="Teléfono" {...register("remitente_telefono", req)} type="number" />
          {addlocalidadrem && <input placeholder="Localidad del remitente" {...register("remitente_location_localidad", req)} />}
        </div>

        <h5 className={`${namespace}--form-title`}>Datos del Destinatario</h5>
        <div className="content-form destinatario">
          <input className={`${namespace}--input`} placeholder="Nombre / Razón social" {...register("destinatario_name", req)} />
          <input className={`${namespace}--input`} placeholder="Direccion" {...register("destinatario_direccion", req)} />
          <Select {...register("destinatario_location",  {
            ...req, 
            onChange: handleChangeDestinatarioLocation,
            validate: v =>  v !== getValues("remitente_location")})} />
          <input className={`${namespace}--input`} placeholder="Teléfono" {...register("destinatario_telefono", req)} type="number" />
          {addlocalidaddes && <input placeholder="Localidad del destinatario" {...register("destinatario_location_localidad", req)} />}
          {(errors && errors.destinatario_location) ? <span role="alert" className={"form-error-same-locations"}>
            La localidad del destinatario debe ser diferente a la localidad del remitente
          </span> : ''}        
	</div>

        <h5 className={`${namespace}--form-title subtitle`}>Retiro de mercadería a domicilio</h5>
        <input className={`${namespace}--input box`} type="checkbox" {...register("retiro_a_domicilio")} />
        
        <h5 className={`${namespace}--form-title subtitle seguro-propio`}>Seguro propio</h5>
        <input className={`${namespace}--input box`} type="checkbox" {...register("seguro_propio")} />
        
        <h5 className={`${namespace}--form-title`}>Detalle de la mercadería</h5>
        {showItems ?
        (arrayItems.map(array => (
              <div className="content-form upgrade-items">
                <button className={`${namespace}--button detail-item`} onClick={(event) => handleDeleteItem(event, array.nombre)}>X</button>
                <p className={`${namespace}--input name-item`}>
                  Detalle: {array.nombre}
                </p>
                <p className={`${namespace}--input detail-item`}>
                  Cantidad: {array.cantidad}
                </p>
                <p className={`${namespace}--input detail-item`}>
                  Largo: {array.largo}
                </p>
                <p className={`${namespace}--input detail-item`}>
                  Ancho: {array.ancho}
                </p>
                <p className={`${namespace}--input detail-item`}>
                  Profundidad: {array.profundidad}
                </p>
                <p className={`${namespace}--input detail-item`}>
                  Kilos: {array.kilos}
                </p>
                <p className={`${namespace}--input detail-item`}>
                  Valor: {array.valor}
                </p>
              </div>
          ))) : ''
      }
        <div className="content-form upgrade-items">
          <input className={`${namespace}--input name-item`} value={nombre} name={"nombre"} placeholder="Detalle de la mercadería" onChange={(e) => { setNombre(e.target.value) }} />
          <input className={`${namespace}--input detail-item`} value={cantidad} name={"cantidad"} type="number" placeholder="Cantidad" onChange={(e) => { setCantidad(e.target.value) }} />
          <input className={`${namespace}--input detail-item`} value={largo} name={"largo"} type="number" placeholder="Largo(cm)" onChange={(e) => { setLargo(e.target.value) }} />
          <input className={`${namespace}--input detail-item`} value={ancho} name={"ancho"} type="number" placeholder="Ancho(cm)" onChange={(e) => { setAncho(e.target.value) }} />
          <input className={`${namespace}--input detail-item`} value={profundidad} name={"profundidad"} type="number" placeholder="Profundidad(cm)" onChange={(e) => { setProfundidad(e.target.value) }} />
          <input className={`${namespace}--input detail-item`} value={kilos} name={"kilos"} type="number" placeholder="Peso(kg)" onChange={(e) => { setKilos(e.target.value) }} />
          <input className={`${namespace}--input detail-item valor-declarado`} value={valor} name={"valor"} type="number" placeholder="Valor declarado($)" onChange={(e) => { setValor(e.target.value) }} />
          <ReactTooltip place="bottom" type="error" effect="solid" />
          <a
            className={`${namespace}--content-button`}
            data-tip="Completá todo los valores de la mercadaría"
            data-tip-disable={disableErrorMessage}
          >
            <button className={`${namespace}--button`} onClick={handleSubmitItem}>Agregar producto +</button>
          </a>
        </div>
        {(productoVacio) ? <span role="alert" className={"form-error-same-locations"}>
            Debe ingresar al menos un producto
          </span> : ''}
        <p className="form-description-valores">*Los valores pasados son estimados, pueden sufrir modificaciones una vez que ingresa la carga al depósito y se cotejan las medidas y el peso. </p>
        <input className={`${namespace}--input`} type="submit" value="Enviar" />
      </form>
    </>
  );
};

export default Quoter;

