import React from "react";
import './App.css';
import NavBar from "./component/nav-bar";
import Footer from "./component/footer";
import {
    Routes,
    Route,
} from "react-router-dom";
import Home from "./page/home";
import Sucursales from "./page/Sucursales";
import Rrhh from "./page/Rrhh";
import Logistica from "./page/Logistica";
import WspButton from "./component/WspButton";

function App() {


  return (
    <div className="App">
        <WspButton />
        <NavBar />
        <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/QUOTER" element={<Home page='QUOTER' />} />
        <Route path="/RETIREMENT" element={<Home page='RETIREMENT' />} />
        <Route path="/sucursales" element={<Sucursales />} />
        <Route path="/logistica-360" element={<Logistica />} />
        <Route path="/recursos-humanos" element={<Rrhh />} />
      </Routes>
      <Footer />

    </div>
  );
}

export default App;
