import React from "react";

const namespace = 'expreso-el-vasquito';

export default function Rrhh() {
	return(
		<section className={`content-app  ${namespace}_recursos-humanos`}>
			<h2 className={`${namespace}_title`}>Trabajá con nosotros!!</h2>
      <p className={`${namespace}_text`}>
      Desde hace más de 60 años somos una empresa dedicada al servicio de transportes de cargas generales con un aporte constante al crecimiento de nuestra Patagonia. 
      Somos una empresa familiar, con don de servicio y excelencia; y nos encontramos en la búsqueda constante de agrandar nuestra familia. Ofrecemos un ambiente laboral basado en valores como la puntualidad, la seguridad, la confianza, la competitividad, la honestidad y el respeto.
      Nos encontramos en constante crecimiento, creando nuevas oportunidades laborales y apoyando el crecimiento de nuestros empleados a nivel profesional y personal.
      Si quieres formar parte de nuestro equipo laboral queremos conocerte! Envíanos un CV a <a target="_blank" href="rrhh@expresoelvasquito.com.ar" alt="rrhh@expresoelvasquito.com.ar ">rrhh@expresoelvasquito.com.ar </a>
      </p>
    </section>
	)
}