import React from 'react';
import MainComponent from "../../component/main";
import NosotrosComponent from "../../component/nosotros";
import Informacion from "../../component/informacion";
import SucursalesComponent from "../../component/sucursales";
import ContactComponent from "../../component/contacto";

const namespace = 'expreso-el-vasquito';

function Home(props) {
	return(
		<div className={namespace}>
			<MainComponent
        page={props.page}
      />
			<NosotrosComponent />
			<Informacion />
			<SucursalesComponent />
			<ContactComponent />
		</div>
	);
}

export default Home;