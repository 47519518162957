const arrInfo = [
    {
        text: 'Entregas y retiros en todas las localidades atendidas',
    },
    {
        text: 'Seguimiento Online',
    },
    {
        text: 'Viajes exclusivos',
    },
    {
        text: 'Viajes consolidados',
    },
    {
        text: 'Gestión de cobro (Contra reembolso)',
    },
    {
        text: 'Gestión devolución de remitos conformes',
    },
    {
        text: 'Etiquetado de productos',
    },
    {
        text: 'Informe de entregas',
    },
    {
        text: 'Atención a supermercados, comercios mayoristas, minoristas y clientes finales',
    },
    {
        text: 'Cargas completas a todo el país',
    },
    {
        text: 'Seguimiento satelital las 24 Hs.',
    },
];

export default arrInfo;
